.loading {
    transform: translate(-50%, -50%) scale(2);
    margin: 1em 1em 0em 2em ;
}

.loading svg polyline {
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.loading svg polyline#back {
    stroke: #ff4d5033;
}

.loading svg polyline#front {
    stroke: #ff4d4f;
    stroke-dasharray: 12, 36;
    stroke-dashoffset: 48;
    animation: dash 1s linear infinite;
}

@keyframes dash {
    62.5% {
        opacity: 0;
    }
    to {
        stroke-dashoffset: 0;
    }
}